

#banner {
    background-color: #1476B6;
    color: white;
}

.logOut {
    background-color: #1476B6;
    color: white;
}

body {
    font-family: 'AmericanSans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #212529;
}

a.navbar-brand {
    white-space: normal;
    text-align: right;
    word-break: break-all;
}

html {
    font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
#link {
    border-radius: 0.2rem;
}
    #link:hover {
        background-color: #346187;
        border: 0px solid black;
    }

NavItem:focus {
    background-color: gray;
}

#navbarLink{
    align-items:flex-start;
}