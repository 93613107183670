.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
}

.dialog {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 0.25rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20rem;
    max-width: 100%;
    width: 28rem;
}

.dialog_content {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    align-content: center;
    padding-top: 0.15rem;
}

.dialog_title {
    font-family: Arial;
    font-size: 1rem;
    line-height: 0.25rem;
    color: dimgrey;
    text-align: center;
}

.line {
}

.dialog_description {
    text-align: center;
    font-family: Arial;
    font-size: 0.85rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    color: dimgrey;
    word-wrap: break-word;
    max-width: 400px;
    max-height: 8rem;
}

.hr {
    margin: 0.5rem;
}

.hrline {
    color: #ff6600;
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    border-width: 12px;
    opacity: 0.5;
}

    .hrline:not([size]) {
        height:2px;
    }

.dialog_footer {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 0.75rem;
    padding-top: 0.5rem;
}


.dialog_btn {
    border-radius: 0.5rem;
    margin-right: 1rem;
    color: #1476B6;
    padding-top: 0.25em;
    padding-bottom: 0.25rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border: none;
}


    .dialog_btn:hover {
        background-color: lightgray;
        color: black;
    }
