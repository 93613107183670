.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
}

.tooltip-box {
    position: absolute;
    top: 50%;
    left: 110%;
    transform: translateY(-50%);
    background-color: #EEF1F2;
    color: #212529;
    padding: 10px;
    border-radius: 4px;
    white-space: normal;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.2s ease-in-out, bottom 0.2s ease-in-out;
    width: 300px;
    max-width: 90vw;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
    box-sizing: border-box;
    overflow: hidden;
    font-weight: normal;
    font-style: normal;
    border: 2px solid black;
}




.tooltip-container:hover .tooltip-box {
    opacity: 1;
}
.tooltip-box ul {
    margin: 0;
    padding-left: 20px; /* Indent for bullet points */
}

.tooltip-box li {
    margin-bottom: 5px;
    list-style-type: disc;
}