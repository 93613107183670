* {
    font-family: "72","72full",Arial,Helvetica,sans-serif;
}

#page {
    margin-left: 1%;
    margin-right: 1%;
    min-height: calc(100vh - 90px); /* Adjust the height dynamically based on viewport and footer height */
    padding-bottom: 100px; /* Ensure there's enough padding for the footer */
}
