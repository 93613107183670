.upload {
    margin-top: 50px;
    margin-bottom: 25px;
    box-sizing: border-box;
    background-color: #EEF1F2;
    padding-bottom: 1rem;
}

.attachheader {
    box-sizing: border-box;
    margin-bottom: 0.5px;
    font-size: 1rem;
    color: #333333;
    font-family: Arial;
    font-weight: 700;
    margin-left: 20px;
    margin-top: 30px;
    padding-top: 15px;
}
.attachmentsBox {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.attachdesc {
    color: grey;
    display: inline-block;
    box-sizing: border-box;
    font-family: Arial;
    color: #666666;
    font-size: 0.875rem;
    margin-left: 20px;
}

.attach {
    margin-left: 40px;
    margin-top: 12px;
    font-size: 1.125rem;
    color: #333333;
    font-family: Arial;
}

.uploaded-files-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0.15rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: white;
    border-radius: 2px;
    padding: 1rem 1.5rem;
}
.uploaded-files-none-list {
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0.15rem;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    background-color: white;
    border-radius: 2px;
    padding: 1rem 1.5rem;
    font-size:25px;
}

.attlist-container {
    display: flex;
    flex-direction: column; /* Arrange elements vertically (second element below the first) */
    align-items: flex-start
}

.attlist {
    color: dimgray;
    margin-bottom: 0.15rem;
    margin-right: 10px;
}

#fileUpload {
    margin: 1rem;
    border:solid 1px gray;
}

#fileUploadButton {
    padding: 1rem;
    color: #3483b8;
    font-weight: bold;
}
    #fileUploadButton:hover {
        background-color: lightgray;
    }

.delete-button {
    margin-left: auto;
}
    .delete-button:hover {
        background-color: lightgray;
    }

.circular-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #EEF1F2;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid #1476B6;
}


    .circular-button::after {
        content: 'X';
        color: #1476B6;
    }
.custom-file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

    .custom-file-input + label {
        background-color: #3498db;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
    }

        .custom-file-input + label::before {
            content: 'Select a File';
        }

        .custom-file-input + label:hover {
            background-color: #2980b9;
        }
