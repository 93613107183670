.footer {
    width: 100%;
    height: 25px;
    background: #627a88;
    color: #fff;
    bottom: 0;
    left: 0;
    padding-bottom: 2px;
    padding-top: 4px;
    position: fixed;
    float: left;
}

.footer-content {
    width: 100%;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    height: 25px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
}


.help-link a {
    font-size: 14px;
    color: #fff;
    text-decoration: underline; 
}

    .help-link a:hover {
        text-decoration: none;
        color: #fff; 
    }