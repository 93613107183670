


#myCasesAll {
}

#myCasesHead {
    display: flex;
    justify-content: space-between;
}

#toggleFilterButton {
    height: 50px;
    color: #1476B6;
    width: 130px;
}
    #toggleFilterButton:hover {
        background-color: lightgray;
    }
#pagination {
    text-align: right;
    margin-top:1%;
}
#pageBar{
}
#pageButton {
    background: white;
    font-size: 20px;
    padding: 10px;
    margin-right: 2px;
    margin-left: 2px;
    border: 0px;
    width: 40px;
    color: #1476B6;
}



    #pageButton:hover {
        font-weight: bold;
        background-color: lightgray;
    }
    #pageButton:disabled {
        color: gray;
        background-color: white;
        font-weight: normal;
    }
button.active {
    color: white;
    background-color: #1476B6;
}

table, th, td {

}
#caseRow {
    height: 64px;
    border: 1px ridge white;
    color: gray;
}
    #caseRow:hover {
        font-weight:bold;
    }
#subject{
    width:30%;
}
#caseNumber {
    width: 10%;
}
#lastUpdated {
    width: 15%;
}
#status {
    width: 15%;
}

.alternate-row {
    background-color: #c2e9ff;
}



#filterBox {
    width: 100%;
    background-color: #EEF1F2;
    display: inline-grid;
    padding-top: 25px;
    padding-bottom: 10px;
}

#filterHead {
    color: #658893;
    font-size: 30px;
    font-family: "72","72full",Arial,Helvetica,sans-serif;
    padding-left: 20px;
    margin-bottom: 2rem;
    border-bottom: 1px solid #d3d4d4;
}

ul {
    padding-left: 0rem;
}

.filtersub {
    display: flex;
    color: dimgrey;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.filterbox {
    width:90%;
    height:50px;
    padding: 0.25rem;
    border-color: #EEF1F2;
}

.dt {
    width: 200px;
    height: 50px;
    margin-left: 0.25rem;
    padding: 0.25rem;
    border-color: #EEF1F2;
}

#casesBox {
    width: 100%;
    border: 1px solid gray;
    word-wrap: break-word;
    font-size: 20px;
    cursor: pointer;
}

#casesHead {
    display: flex;
    justify-content: space-evenly
}

#filterItem {
    text-align: left;
    padding-left: 1rem;
    width:25%;
    font-size:18px;
}
.dt {
    width: 45%;
    height: 50px;
    margin-left: 0.25rem;
    padding: 0.25rem;
    border-color: #EEF1F2;
}

#applyFilter {
    display: flex;
    justify-content: flex-end;
    float: right;
    width: 97%;
}

.btn {
    background-color: #1476B6;
    color: white;
    border-radius: 0.25rem;
    margin-right: 10px;
}

.tableheader {
    background-color: #1476B6;
}

.columnname {
    color: white;
    font-weight: 500;
    padding: 1rem;
}

button.notActive:hover {
    font-weight: bold;
    background-color: lightgray;
}
