h1 {
    font-size: 35px;
    color: #1476B6;
    margin-bottom:30px;
    font-family: Arial;
}
h2 {
    font-size: 22px;
    color: grey;
    margin-bottom: 20px;
    font-family: Arial;
}

.pref-contact{
    margin-bottom:40px;
}
.pref-values {
    position: relative;
    margin-bottom: 15px;
    font-size: 18px;
    box-sizing: border-box;
    width: 75%;
}

.radio {
    margin-bottom: 20px;
    color: grey;
    margin-left: 15px;
}

.radioalign {
    display: flex;
    align-items: center;
}

.radiosize {
    height: 22px;
    width: 22px;
    margin-right: 15px;
    accent-color: #1476B6;
}

.cancel-button {
    border: 1px solid #1476B6;
    color: #1476B6;
    box-sizing: border-box;
    width: 37%;
    border-radius: 3px;
    float: left;
    background-color: white;
    margin-right: 1%;
    height: 40px;
    margin-top: 20px;
}

.save-button {
    box-sizing: border-box;
    width: 37%;
    float: left;
    color: white;
    background-color: #1476B6;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #1476B6;
    margin-top: 20px;
}

.message{
    color:red;
}
.disabled-label {
    color: lightgray;
}
