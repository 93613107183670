.casedetail {
    font-size: 32px;
    color: #1476B6;
    margin-bottom: 0.25rem;
    font-family: Arial;
}
h2 {
    margin-top: 30px;
    font-size: 22px;
    color: grey;
    margin-bottom: 10px;
    font-family: Arial;
}

.caseheader {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
    padding-bottom: 10px;
}

.cancel-btn {
    display: flex;
    border: 1px solid #1476B6;
    color: #1476B6;
    width: 250px;
    border-radius: 3px;
    float: right;
    background-color: white;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin-right:25%;
}
    .cancel-btn:hover {
        background-color: lightgray;
    }

.update-case-header {
    margin-bottom: 20px;
    font-weight: 500;
}

.update-case-topic {
    margin-left: 35px;
}

.update-case-status {
    margin-left: 35px;
}

.save-btn {
    width: 250px;
    float: right;
    color: white;
    background-color: #1476B6;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #1476B6;
    margin-top:10px;
}
    .save-btn:hover {
        background-color: #346187;
        border: 0px solid black;
    }

#desc::placeholder {
    font-style: italic;
}

.bloc-tabs {
    display: flex;
    margin-top: 7rem;
}

#attachBox {
    width: 100%;
    border: 1px solid gray;
    word-wrap: break-word;
    color:black;
}

.attachContent{
    border: 1px solid gray;
}
.tabs {
    padding: 5px;
    text-align: center;
    width: 50%;
    background: rgba(128, 128, 128, 0.075);
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    color: dimgray;
    /*  position: relative;*/
}

/* .tabs:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.274);
    }

   .active-tabs::before {
        content: "";
        display: block;
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 2px);
        height: 5px;
        background: rgb(88, 147, 241);
    } */

.active-tabs {
    background: white;
    border-bottom: 1px solid transparent;
    color: #1476B6;
    font-weight: bold;
}

.content-tabs {
    flex-grow: 1;
}

.content {
    background: white;
    padding: 15px;
    display: none;
}

    .content h2 {
        padding: 0px 0 5px 0px;
    }

.active-content {
    display: block;
}

button {
    border: none;
}

.columnfn {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    color: white;
    font-weight: 400;
}

.columndtup {
    text-align: left;
    padding-left: 20px;
    padding-bottom: 15px;
    padding-top: 15px;
    width: 250px;
    color: white;
    font-weight: 400;
}

button.save-btn:disabled {
    background-color: lightgray;
}

#updateHalf{
    width:75%;
}