.openheader {
    font-size: 32px;
    color: #1476B6;
    margin-bottom: 20px;
    font-family: Arial;
}
h2 {
    font-size: 22px;
    color: grey;
    margin-bottom: 20px;
    font-family: Arial;
}

.open-case-header {
    margin-bottom: 20px;
}
.attachmentForm {
    width: 75%;
}
.open-case-item {
    position: relative;
    margin-bottom: 15px;
    font-size: 16px;
    box-sizing: border-box;
    width: 75%;
}
.prefdesc {
    font-size: 18px;
    color: #666666;
}

.radio {
    margin-bottom: 20px;
    color: #666666;
    margin-left: 15px;
}

.radioalign {
    display: flex;
    align-items: center;
}

.radiosize {
    height: 22px;
    width: 22px;
    margin-right: 15px;
    accent-color: #1476B6;
}

.cancel-button {
    border: 1px solid #1476B6;
    color: #1476B6;
    box-sizing: border-box;
    width: 37%;
    border-radius: 3px;
    float: left;
    background-color: white;
    margin-right: 1%;
    height: 40px;
    margin-top: 20px;
}
    .cancel-button:hover {
        background-color: lightgray;
    }
.save-button {
    box-sizing: border-box;
    width: 37%;
    float: left;
    color: white;
    background-color: #1476B6;
    border-radius: 3px;
    height: 40px;
    border: 1px solid #1476B6;
    margin-top: 20px;
}
    .save-button:hover {
        background-color: #346187;
        border: 0px solid black;
    }
p {
    color: red;
}
button.save-button:disabled {
    background-color: lightgray;
}
#topicSelect {
    appearance: auto;
}
.custom-select {
    appearance: auto;
}
.disabled-label {
    color: lightgray;
}
.save-button{
    margin-bottom: 2%;
}